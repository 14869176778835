<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        {{ titleZh  }}
      </div>
      <div class="topic_desc_en">
        {{ titleEn }}
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <div class="module_desc" v-html="thematicDesc">
      </div>
      <div class="section_content">
        <div class="section_title section_title_range">服务范围</div>
        <div class="section_main">
          <div class="main_topic">
            {{rangesTitle}}
          </div>
          <div class="main_content">
            <div class="main_col" v-for="(cItem,cIdx) in dataInfos" :key="cIdx">
              <template v-for="(rItem,rIdx) in cItem">
                <div class="main_item" :key="rIdx" v-if="rItem.name">
                  <div class="main_item_topic">
                    {{rItem.name}}
                  </div>
                  <div class="main_item_detail" v-for="(dItem,dIdx) in rItem.details" :key="dIdx">
                    <div class="main_item_detail_name">
                      {{dItem.name}}
                    </div>
                    <div class="main_item_detail_line" v-for="(lItem,lIdx) in dItem.infos" :key="lIdx">
                      <div class="main_item_detail_icon"></div>
                      <div class="main_item_detail_value" v-html="lItem">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="main_item_pic" :key="rIdx" v-else>
                  <div class="main_item_pic_label">
                    {{rItem.label}}
                  </div>
                  <div class="main_item_pic_content">
                    <img :src="rItem.pic">
                  </div>
                </div>

              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data () {
    return {
      "rangesTitle":"",
      "thematicDesc":"",
      "dataInfos":[],
      "titleZh":"",
      "titleEn":""
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      // let lang = this.$i18n.locale;
      return "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取页面数据
    getData(){
      let _this = this;
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=safeService&secondaryTitle=drugSeekingMotivationReview`).then(res=>{
        if(res.status==200&&res.data.code==0){
          // 获取配置数据
          let jsonStr = res.data.page?res.data.page.configs:"{}";
          let jsonStrPure = jsonStr.replace(/\n/g,"").replace(/\r/g,"");
          let configData = JSON.parse(jsonStrPure); 
          
          // 处理配置数据
          _this.rangesTitle = configData.rangesTitle;
          _this.thematicDesc = configData.thematicDesc;
          _this.titleZh = configData.titleZh;
          _this.titleEn = configData.titleEn;
          _this.dataInfos = configData.dataInfos.map(arr=>{
            return arr.map(i=>{
              if(i.pic){
                return Object.assign({},i,{
                  pic:`${window.PICSEVERURL}/${i.pic}`
                })
              }else{
                return i;
              }
            })
          });
        }else{
          _this.$notify({
            title: '失败',
            message: "药物代谢动力评价数据请求失败",
            type: 'error'
          });
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getData();
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .topic_panel {
    position: relative;
    margin-bottom: 3rem;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.5rem;
      color: #222222;
      line-height: 2.4rem;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 1.4rem;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    width: 100%;
    margin-bottom: 70px;
    .module_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #333333;
      line-height: 1.4rem;
      margin-bottom: 2rem;
    }
    .section_content{
      .section_title{
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.2rem;
        color: #000000;
        height: 2rem;
        line-height: 2rem;
        padding-left: 3rem;
        box-sizing: border-box;
        &.section_title_feature{
          background: url('./images/titleBg@feature.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
        &.section_title_range{
          background: url('./images/titleBg@range.png') 0 0 no-repeat;
          background-size: auto 34px;
          background-position: center left;
        }
      }
      .section_main{
        background: #F6F7F9;
        padding: 0.5rem;
        box-sizing: border-box;
        .main_topic{
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 1.5rem;
          color: #0086D1;
          line-height: 2rem;
        }
        .main_content{
          margin-top: 0.8rem;
          // display: flex;
          .main_col{
            margin-right: 0.5rem;
            display: flex;
            flex-direction: column;
            &:last-child{
              margin-right: 0;
            }
            .main_item{
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 1.5rem;
              .main_item_topic{
                padding: 0 1.5rem;
                height: 44px;
                background: #0086D1;
                display: flex;
                align-items: center;
                font-size: 1.0rem;
                color: #FFFFFF;
              }
              .main_item_detail{
                .main_item_detail_name{
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                  font-family: PingFang SC, PingFang SC;
                  font-weight: 600;
                  font-size: 1.2rem;
                  color: #0086D1;
                  line-height: 1.4rem;
                }
                .main_item_detail_line{
                  display: flex;
                  align-items: flex-start;
                  margin-bottom: 0.5rem;
                  .main_item_detail_icon{
                    width: 1rem;
                    height: 1rem;
                    background: url("./images/icon@checked.png") 0 0 no-repeat;
                    background-size: 100% auto;
                    background-position: center left;
                    // margin: 0.5rem 7px 0.5rem 0;
                    margin-right: 0.5rem;
                  }
                  .main_item_detail_value{
                    flex: 1;
                    overflow: hidden;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 1rem;
                    color: #333333;
                    line-height: 1.4rem;
                  }
                }
              }
            }
            .main_item_pic{
              .main_item_pic_label{
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 1.2rem;
                color: #0086D1;
                line-height: 44px;
                margin-bottom: 1.4rem;
              }
              .main_item_pic_content{
                img{
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>